import React, { useEffect } from 'react';
import Layout from "../layout"
import { graphql } from "gatsby"
import SEO from "../components/SEO"
import $ from 'jquery'
import styled from "styled-components"


import { useTranslation, I18nextContext } from "gatsby-plugin-react-i18next"

const TestPage = ({ location }) => {
    const { t } = useTranslation()
    const activeLanguage = React.useContext(I18nextContext).language

    useEffect(() => {

        /** FORM 1 */
        /**
        var current_fs, next_fs, previous_fs; //fieldsets
        var opacity;
        var current = 1;
        var steps = $("fieldset").length;

        setProgressBar(current);

        $(".next").click(function () {

            current_fs = $(this).parent();
            next_fs = $(this).parent().next();

            //Add Class Active
            $("#progressbar li").eq($("fieldset").index(next_fs)).addClass("active");

            //show the next fieldset
            next_fs.show();
            //hide the current fieldset with style
            current_fs.animate({ opacity: 0 }, {
                step: function (now) {
                    // for making fielset appear animation
                    opacity = 1 - now;

                    current_fs.css({
                        'display': 'none',
                        'position': 'relative'
                    });
                    next_fs.css({ 'opacity': opacity });
                },
                duration: 500
            });
            setProgressBar(++current);
        });

        $(".previous").click(function () {

            current_fs = $(this).parent();
            previous_fs = $(this).parent().prev();

            //Remove class active
            $("#progressbar li").eq($("fieldset").index(current_fs)).removeClass("active");

            //show the previous fieldset
            previous_fs.show();

            //hide the current fieldset with style
            current_fs.animate({ opacity: 0 }, {
                step: function (now) {
                    // for making fielset appear animation
                    opacity = 1 - now;

                    current_fs.css({
                        'display': 'none',
                        'position': 'relative'
                    });
                    previous_fs.css({ 'opacity': opacity });
                },
                duration: 500
            });
            setProgressBar(--current);
        });

        function setProgressBar(curStep) {
            var percent = parseFloat(100 / steps) * curStep;
            percent = percent.toFixed();
            $(".progress-bar")
                .css("width", percent + "%")
        }

        $(".submit").click(function () {
            return false;
        })*/

        /** END FORM 1 */

        /** FORM 2 */
        $("body").on("keyup", "form", function(e){
            if (e.which == 13){
              if ($("#next").is(":visible") && $("fieldset.current").find("input, textarea").valid() ){
                e.preventDefault();
                nextSection();
                return false;
              }
            }
          });
           
           
          $("#next").on("click", function(e){
            nextSection();
          });
           
          $("form").on("submit", function(e){
            if ($("#next").is(":visible") || $("fieldset.current").index() < 3){
              e.preventDefault();
            }
          });
           
          function goToSection(i){
            $("fieldset:gt("+i+")").removeClass("current").addClass("next");
            $("fieldset:lt("+i+")").removeClass("current");
            $("li").eq(i).addClass("current").siblings().removeClass("current");
            setTimeout(function(){
              $("fieldset").eq(i).removeClass("next").addClass("current active");
                if ($("fieldset.current").index() == 3){
                  $("#next").hide();
                  $("input[type=submit]").show();
                } else {
                  $("#next").show();
                  $("input[type=submit]").hide();
                }
            }, 80);
           
          }
           
          function nextSection(){
            var i = $("fieldset.current").index();
            if (i < 3){
              $("li").eq(i+1).addClass("active");
              goToSection(i+1);
            }
          }
           
          $("li").on("click", function(e){
            var i = $(this).index();
            if ($(this).hasClass("active")){
              goToSection(i);
            } else {
              alert("Please complete previous sections first.");
            }
          });
           
           
        /** END FORM 2 */
    })

    return (
        <Layout location={location} footer={t("footer", { returnObjects: true })} header={t("header", { returnObjects: true })}>
            <SEO
                title="Ramalhos - Pure Baking Pleasure"
                titleTemplate="%s"
                keywords={t("metaHome", { returnObjects: true })["keywords"]}
                description={t("metaHome", { returnObjects: true })["description"]}
                img={t("metaHome", { returnObjects: true })["image"]}
                imageHeight="628"
                imageWidth="1200"
                lang={activeLanguage}
                homepage={true}
            />
            <Wrapper>
                {/**<div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-11 col-sm-10 col-md-10 col-lg-6 col-xl-5 text-center p-0 mt-3 mb-2">
                            <div className="card px-0 pt-4 pb-0 mt-3 mb-3">
                                <h2 id="heading">Sign Up Your User Account</h2>
                                <p>Fill all form field to go to next step</p>
                                <form id="msform">
                                    <ul id="progressbar">
                                        <li className="active" id="account"><strong>Account</strong></li>
                                        <li id="personal"><strong>Personal</strong></li>
                                        <li id="payment"><strong>Image</strong></li>
                                        <li id="confirm"><strong>Finish</strong></li>
                                    </ul>
                                    <div className="progress">
                                        <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div> <br />
                                    <fieldset>
                                        <div className="form-card">
                                            <div className="row">
                                                <div className="col-7">
                                                    <h2 className="fs-title">Account Information:</h2>
                                                </div>
                                                <div className="col-5">
                                                    <h2 className="steps">Step 1 - 4</h2>
                                                </div>
                                            </div> <label className="fieldlabels">Email: *</label> <input type="email" name="email" placeholder="Email Id" /> <label className="fieldlabels">Username: *</label>
                                            <input type="text" name="uname" placeholder="UserName" /> <label className="fieldlabels">Password: *</label>
                                            <input type="password" name="pwd" placeholder="Password" /> <label className="fieldlabels">Confirm Password: *</label>
                                            <input type="password" name="cpwd" placeholder="Confirm Password" />
                                        </div>
                                        <input type="button" name="next" className="next action-button" value="Next" />
                                    </fieldset>
                                    <fieldset>
                                        <div className="form-card">
                                            <div className="row">
                                                <div className="col-7">
                                                    <h2 className="fs-title">Personal Information:</h2>
                                                </div>
                                                <div className="col-5">
                                                    <h2 className="steps">Step 2 - 4</h2>
                                                </div>
                                            </div> <label className="fieldlabels">First Name: *</label> <input type="text" name="fname" placeholder="First Name" />
                                            <label className="fieldlabels">Last Name: *</label> <input type="text" name="lname" placeholder="Last Name" />
                                            <label className="fieldlabels">Contact No.: *</label> <input type="text" name="phno" placeholder="Contact No." />
                                            <label className="fieldlabels">Alternate Contact No.: *</label> <input type="text" name="phno_2" placeholder="Alternate Contact No." />
                                        </div>
                                        <input type="button" name="next" className="next action-button" value="Next" />
                                        <input type="button" name="previous" className="previous action-button-previous" value="Previous" />
                                    </fieldset>
                                    <fieldset>
                                        <div className="form-card">
                                            <div className="row">
                                                <div className="col-7">
                                                    <h2 className="fs-title">Image Upload:</h2>
                                                </div>
                                                <div className="col-5">
                                                    <h2 className="steps">Step 3 - 4</h2>
                                                </div>
                                            </div> <label className="fieldlabels">Upload Your Photo:</label>
                                            <input type="file" name="pic" accept="image/*" />
                                            <label className="fieldlabels">Upload Signature Photo:</label>
                                            <input type="file" name="pic" accept="image/*" />
                                        </div>
                                        <input type="button" name="next" className="next action-button" value="Submit" />
                                        <input type="button" name="previous" className="previous action-button-previous" value="Previous" />
                                    </fieldset>
                                    <fieldset>
                                        <div className="form-card">
                                            <div className="row">
                                                <div className="col-7">
                                                    <h2 className="fs-title">Finish:</h2>
                                                </div>
                                                <div className="col-5">
                                                    <h2 className="steps">Step 4 - 4</h2>
                                                </div>
                                            </div> <br /><br />
                                            <h2 className="purple-text text-center"><strong>SUCCESS !</strong></h2> <br />
                                            <div className="row justify-content-center">
                                                <div className="col-3"> <img src="https://i.imgur.com/GwStPmg.png" className="fit-image" /> </div>
                                            </div> <br /><br />
                                            <div className="row justify-content-center">
                                                <div className="col-7 text-center">
                                                    <h5 className="purple-text text-center">You Have Successfully Signed Up</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>*/}
            </Wrapper>
            <Wrapper2>
                <form id="signup" action="somewhere" method="POST">
                    <ul id="section-tabs">
                        <li className="current active"><span>1.</span> Creds</li>
                        <li><span>2.</span> Deets</li>
                        <li><span>3.</span> Settings</li>
                        <li><span>4.</span> Last Words</li>
                    </ul>
                    <div id="fieldsets">
                        <fieldset className="current">
                            <label htmlFor="email">Email:</label>
                            <input name="email" type="email" className="required email" />
                            <label name="password" htmlFor="password">Password:</label>
                            <input type="password" minLength={10} className="required" />
                        </fieldset>
                        <fieldset className="next">
                            <label htmlFor="username">Username:</label>
                            <input name="username" type="text" />
                            <label htmlFor="bio">Short Bio:</label>
                            <textarea name="bio" className="required"></textarea>
                        </fieldset>
                        <fieldset className="next">
                            <label htmlFor="interests">Basic Interests:</label>
                            <textarea name="bio"></textarea>
                            <p>Receive newsletter?<br />
                                <input type="radio" name="newsletter" value="yes" /><label htmlFor="newsletter">yes</label>
                                <input type="radio" name="newsletter" value="no" /><label htmlFor="newsletter">no</label>
                            </p>
                        </fieldset>
                        <fieldset className="next">
                            <label htmlFor="referrer">Referred by:</label>
                            <input type="text" name="referrer" />
                            <label htmlFor="phone">Daytime Phone:</label>
                            <input type="tel" name="phone" />
                        </fieldset>
                        <a className="btn" id="next">Next Section ▷</a>
                        <input type="submit" className="btn" />
                    </div>
                </form>
            </Wrapper2>
        </Layout>
    )
}

export default TestPage

export const QueryHomepagePT = graphql`
                                query($language: String!) {
                                    locales: allLocale(filter: {ns: { in: ["home", "turboram", "form", "footer", "header"] }, language: {eq: $language } }) {
                                    edges {
                                    node {
                                    ns
          data
                                language
        }
      }
    }
  }
                                `


const Wrapper = styled.div`

                                #heading {
                                    text - transform: uppercase;
                                color: #673AB7;
                                font-weight: normal
}

                                #msform {
                                    text - align: center;
                                position: relative;
                                margin-top: 20px
}

                                #msform fieldset {
                                    background: white;
                                border: 0 none;
                                border-radius: 0.5rem;
                                box-sizing: border-box;
                                width: 100%;
                                margin: 0;
                                padding-bottom: 20px;
                                position: relative
}

                                .form-card {
                                    text - align: left
}

                                #msform fieldset:not(:first-of-type) {
                                    display: none
}

                                #msform input,
                                #msform textarea {
                                    padding: 8px 15px 8px 15px;
                                border: 1px solid #ccc;
                                border-radius: 0px;
                                margin-bottom: 25px;
                                margin-top: 2px;
                                width: 100%;
                                box-sizing: border-box;
                                font-family: montserrat;
                                color: #2C3E50;
                                background-color: #ECEFF1;
                                font-size: 16px;
                                letter-spacing: 1px
}

                                #msform input:focus,
                                #msform textarea:focus {
                                    -moz - box - shadow: none !important;
                                -webkit-box-shadow: none !important;
                                box-shadow: none !important;
                                border: 1px solid #673AB7;
                                outline-width: 0
}

                                #msform .action-button {
                                    width: 100px;
                                background: #673AB7;
                                font-weight: bold;
                                color: white;
                                border: 0 none;
                                border-radius: 0px;
                                cursor: pointer;
                                padding: 10px 5px;
                                margin: 10px 0px 10px 5px;
                                float: right
}

                                #msform .action-button:hover,
                                #msform .action-button:focus {
                                    background - color: #311B92
}

                                #msform .action-button-previous {
                                    width: 100px;
                                background: #616161;
                                font-weight: bold;
                                color: white;
                                border: 0 none;
                                border-radius: 0px;
                                cursor: pointer;
                                padding: 10px 5px;
                                margin: 10px 5px 10px 0px;
                                float: right
}

                                #msform .action-button-previous:hover,
                                #msform .action-button-previous:focus {
                                    background - color: #000000
}

                                .card {
                                    z - index: 0;
                                border: none;
                                position: relative
}

                                .fs-title {
                                    font - size: 25px;
                                color: #673AB7;
                                margin-bottom: 15px;
                                font-weight: normal;
                                text-align: left
}

                                .purple-text {
                                    color: #673AB7;
                                font-weight: normal
}

                                .steps {
                                    font - size: 25px;
                                color: gray;
                                margin-bottom: 10px;
                                font-weight: normal;
                                text-align: right
}

                                .fieldlabels {
                                    color: gray;
                                text-align: left
}

                                #progressbar {
                                    margin - bottom: 30px;
                                overflow: hidden;
                                color: lightgrey
}

                                #progressbar .active {
                                    color: #673AB7
}

                                #progressbar li {
                                    list - style - type: none;
                                font-size: 15px;
                                width: 25%;
                                float: left;
                                position: relative;
                                font-weight: 400
}

                                #progressbar #account:before {
                                    font - family: FontAwesome;
                                content: "\f13e"
}

                                #progressbar #personal:before {
                                    font - family: FontAwesome;
                                content: "\f007"
}

                                #progressbar #payment:before {
                                    font - family: FontAwesome;
                                content: "\f030"
}

                                #progressbar #confirm:before {
                                    font - family: FontAwesome;
                                content: "\f00c"
}

                                #progressbar li:before {
                                    width: 50px;
                                height: 50px;
                                line-height: 45px;
                                display: block;
                                font-size: 20px;
                                color: #ffffff;
                                background: lightgray;
                                border-radius: 50%;
                                margin: 0 auto 10px auto;
                                padding: 2px
}

                                #progressbar li:after {
                                    content: '';
                                width: 100%;
                                height: 2px;
                                background: lightgray;
                                position: absolute;
                                left: 0;
                                top: 25px;
                                z-index: -1
}

                                #progressbar li.active:before,
                                #progressbar li.active:after {
                                    background: #673AB7
}

                                .progress {
                                    height: 20px
}

                                .progress-bar {
                                    background - color: #673AB7
}

                                .fit-image {
                                    width: 100%;
                                object-fit: cover
}
`

const Wrapper2 = styled.div`

#signup {
    width: 600px;
    height: auto;
    padding: 20px;
    background: #fff;
    margin: 80px auto;
    position: relative;
    min-height: 300px;
  }
  #fieldsets {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    padding: 20px;
    box-sizing: border-box;
    overflow: hidden;
  }
  input[type=text],
  input[type=email],
  input[type=password],
  input[type=tel],
  textarea {
    display: block;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #ddd;
    padding: 8px;
    margin-bottom: 8px;
    position: relative;
    &:focus {
      outline: none;
      border: 1px solid darken(#2cbab2,10%);
    }
  }
   
  input[type=radio]{
    margin: 6px;
    display: inline-block;
  }
  fieldset {
    border: none;
    position: absolute;
    left: -640px;
    width: 600px;
    padding: 10px 0;
    transition: all 0.3s linear;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
    opacity: 0;
    &.current {
      left: 20px;
      opacity: 1;
    }
    &.next {
      left: 640px;
    }
  }
  input[type=submit] {
    display: none;
    border: none;
  }
  #section-tabs {
    font-size: 0.8em;
    height: 50px;
    position: relative;
    margin-top: -50px;
    margin-bottom: 50px;
    padding: 0;
    font-weight: bold;
    list-style: none;
    text-transform: uppercase;
    li {
      color: #a7a7a7;
      span {
        color: #bababa;
      }
      cursor: not-allowed;
      &.active {
        color: #444;
        cursor: pointer;
      }
      border-left: 1px solid #aaa;
      text-decoration: none;
      padding: 0 6px;
      float: left;
      width: 25%;
      box-sizing: border-box;
      text-align: center;
      font-weight: bold;
      line-height: 30px;
      background: #ddd;
      position: relative;
      &:after {
        content: "";
        display: block;
        margin-left: 0;
        position: absolute;
        left: 0;
        top: 0;
      }
      &.current {
        opacity: 1;
        background: #fff;
        z-index: 999;
        border-left: none;
        &:after {
          border: 15px solid transparent;
          border-left: 15px solid #2cbab2;
        }
      }
    }
  }
  .error {
    color: #bf2424;
    display: block;
  }
  input.error, textarea.error {
    border-color: #bf2424;
    &:focus {
      border-color: #bf2424;
    }
  }
  label.error {
      margin-bottom: 20px;
  }
  input.valid {
    color: green;
  }
  label.valid {
    position: absolute;
    right: 20px;
  }
  input+.valid, textarea+.valid {
    display: none;
  }
  .valid+.valid {
    display: inline;
    position: absolute;
    right: 10px;
    margin-top: -36px;
    color: green;
  }
   
  .btn {
    border: none;
    padding: 8px;
    background: #2cbab2;
    cursor: pointer;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    &:hover {
       background: darken(#2cbab2, 6%);
    }
    color: #fff;
    position: absolute;
    bottom: 20px;
    right: 20px;
    font-family: 'Merriweather Sans', sans-serif;
  }
`